const DEFAULT_SERVICE_KEY = 'DEFAULT';
const EXTENDED_SEARCH = 'EXTENDED_SEARCH';
const MAP = 'MAP';
const ROUTING = 'ROUTING';
const SEARCH = 'SEARCH';
const TRAFFIC_FLOW = 'TRAFFIC_FLOW';
const TRAFFIC_INCIDENTS = 'TRAFFIC_INCIDENTS';

export const SERVICE_TYPES = {
    'DEFAULT': DEFAULT_SERVICE_KEY,
    EXTENDED_SEARCH,
    MAP,
    ROUTING,
    SEARCH,
    TRAFFIC_FLOW,
    TRAFFIC_INCIDENTS
};
